:root {
  --alertalert-50: rgba(252, 237, 240, 1);
  --alertalert-500: rgba(220, 76, 100, 1);
  --body-1-font-family: "Poppins-SemiBold", Helvetica;
  --body-1-font-size: 24px;
  --body-1-font-style: normal;
  --body-1-font-weight: 600;
  --body-1-letter-spacing: 0px;
  --body-1-line-height: normal;
  --body-2-font-family: "Poppins-Regular", Helvetica;
  --body-2-font-size: 18px;
  --body-2-font-style: normal;
  --body-2-font-weight: 400;
  --body-2-letter-spacing: 0px;
  --body-2-line-height: normal;
  --body-3-font-family: "Poppins-Regular", Helvetica;
  --body-3-font-size: 16.600000381469727px;
  --body-3-font-style: normal;
  --body-3-font-weight: 400;
  --body-3-letter-spacing: 0px;
  --body-3-line-height: normal;
  --body-bold-font-family: "Montserrat-SemiBold", Helvetica;
  --body-bold-font-size: 16px;
  --body-bold-font-style: normal;
  --body-bold-font-weight: 600;
  --body-bold-letter-spacing: 0px;
  --body-bold-line-height: 120.00000476837158%;
  --body-font-family: "Poppins-Regular", Helvetica;
  --body-font-size: 16px;
  --body-font-style: normal;
  --body-font-weight: 400;
  --body-letter-spacing: 0px;
  --body-line-height: 120.00000476837158%;
  --caption-font-family: "Poppins-Regular", Helvetica;
  --caption-font-size: 13px;
  --caption-font-style: normal;
  --caption-font-weight: 400;
  --caption-letter-spacing: 0px;
  --caption-line-height: 120.00000476837158%;
  --darkdark-100: rgba(186, 186, 186, 1);
  --darkdark-200: rgba(153, 153, 153, 1);
  --darkdark-50: rgba(233, 233, 233, 1);
  --darkdark-500: rgba(34, 34, 34, 1);
  --foundation-failedf100: rgba(252, 111, 111, 1);
  --foundation-failedf200: rgba(250, 48, 48, 1);
  --foundation-failedf300: rgba(249, 6, 6, 1);
  --foundation-failedf400: rgba(174, 4, 4, 1);
  --foundation-failedf50: rgba(254, 230, 230, 1);
  --foundation-failedf500: rgba(152, 4, 4, 1);
  --foundation-failedf75: rgba(253, 153, 153, 1);
  --foundation-failedf700: rgba(152, 4, 4, 1);
  --foundation-neutralneutral-1: rgba(255, 255, 255, 1);
  --foundation-neutralneutral-10: rgba(38, 38, 38, 1);
  --foundation-neutralneutral-11: rgba(31, 31, 31, 1);
  --foundation-neutralneutral-12: rgba(20, 20, 20, 1);
  --foundation-neutralneutral-13: rgba(0, 0, 0, 1);
  --foundation-neutralneutral-2: rgba(252, 252, 252, 1);
  --foundation-neutralneutral-3: rgba(245, 245, 245, 1);
  --foundation-neutralneutral-4: rgba(240, 240, 240, 1);
  --foundation-neutralneutral-5: rgba(217, 217, 217, 1);
  --foundation-neutralneutral-6: rgba(191, 191, 191, 1);
  --foundation-neutralneutral-7: rgba(140, 140, 140, 1);
  --foundation-neutralneutral-8: rgba(89, 89, 89, 1);
  --foundation-neutralneutral-9: rgba(69, 69, 69, 1);
  --foundation-primaryp100: rgba(115, 125, 150, 1);
  --foundation-primaryp200: rgba(55, 69, 105, 1);
  --foundation-primaryp300: rgba(14, 31, 74, 1);
  --foundation-primaryp400: rgba(10, 22, 52, 1);
  --foundation-primaryp50: rgba(231, 233, 237, 1);
  --foundation-primaryp500: rgba(9, 19, 45, 1);
  --foundation-primaryp75: rgba(156, 163, 181, 1);
  --foundation-secondaarys100: rgba(157, 181, 206, 1);
  --foundation-secondaarys200: rgba(115, 149, 185, 1);
  --foundation-secondaarys300: rgba(86, 127, 171, 1);
  --foundation-secondaarys400: rgba(60, 89, 120, 1);
  --foundation-secondaarys50: rgba(238, 242, 247, 1);
  --foundation-secondaarys500: rgba(52, 77, 104, 1);
  --foundation-secondaarys75: rgba(186, 203, 221, 1);
  --foundation-successs100: rgba(133, 178, 113, 1);
  --foundation-successs200: rgba(80, 145, 52, 1);
  --foundation-successs300: rgba(44, 123, 10, 1);
  --foundation-successs400: rgba(31, 86, 7, 1);
  --foundation-successs50: rgba(234, 242, 231, 1);
  --foundation-successs500: rgba(27, 75, 6, 1);
  --foundation-successs75: rgba(168, 201, 155, 1);
  --foundationprimaryp-200: rgba(55, 69, 105, 1);
  --foundationprimaryp-500: rgba(9, 19, 45, 1);
  --heading-1-font-family: "Poppins-Regular", Helvetica;
  --heading-1-font-size: 68.80000305175781px;
  --heading-1-font-style: normal;
  --heading-1-font-weight: 400;
  --heading-1-letter-spacing: 0px;
  --heading-1-line-height: normal;
  --heading-2-font-family: "Poppins-Regular", Helvetica;
  --heading-2-font-size: 60.79999923706055px;
  --heading-2-font-style: normal;
  --heading-2-font-weight: 400;
  --heading-2-letter-spacing: 0px;
  --heading-2-line-height: normal;
  --heading-3-font-family: "Poppins-Regular", Helvetica;
  --heading-3-font-size: 52.79999923706055px;
  --heading-3-font-style: normal;
  --heading-3-font-weight: 400;
  --heading-3-letter-spacing: 0px;
  --heading-3-line-height: normal;
  --heading-4-font-family: "Poppins-Regular", Helvetica;
  --heading-4-font-size: 44.79999923706055px;
  --heading-4-font-style: normal;
  --heading-4-font-weight: 400;
  --heading-4-letter-spacing: 0px;
  --heading-4-line-height: normal;
  --heading-5-font-family: "Poppins-Regular", Helvetica;
  --heading-5-font-size: 36.79999923706055px;
  --heading-5-font-style: normal;
  --heading-5-font-weight: 400;
  --heading-5-letter-spacing: 0px;
  --heading-5-line-height: normal;
  --heading-6-font-family: "Poppins-Regular", Helvetica;
  --heading-6-font-size: 28.799999237060547px;
  --heading-6-font-style: normal;
  --heading-6-font-weight: 400;
  --heading-6-letter-spacing: 0px;
  --heading-6-line-height: normal;
  --primary-1: rgba(14, 31, 74, 1);
  --primary-2: rgba(25, 52, 120, 1);
  --primary-3: rgba(82, 104, 159, 1);
  --primaryprimary-500: rgba(160, 32, 240, 1);
  --button-primary: #09132d;
  --secondarysecondary-500: rgba(144, 238, 144, 1);
  --successsuccess-50: rgba(232, 246, 237, 1);
  --successsuccess-500: rgba(20, 164, 77, 1);
  --texttext-200: rgba(178, 178, 178, 1);
  --texttext-500: rgba(88, 87, 87, 1);
  --warningwarning-50: rgba(252, 246, 232, 1);
  --warningwarning-500: rgba(228, 161, 27, 1);
  --whitewhite-200: rgba(255, 255, 255, 1);
}
